<template>
  <div class="bg-gray-100">
    <v-card class="p-2 bg-gray-100">
      <p class="">Importação</p>
      <v-btn color="primary" outlined x-small @click="$refs.carregaplanilharef.show()">
        <i class="btn-icon-left" v-i:duo#upload#18></i>
        <span>selecionar planilha</span>
      </v-btn>
      <v-btn class="ms-2" color="primary" outlined x-small @click="importar">
        <i class="btn-icon-left" v-i:duo#target#18></i>
        <span>importar</span>
      </v-btn>
    </v-card>
    <carregaplanilha ref="carregaplanilharef" @atualiza="atualizaPlanilha"/>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import carregaplanilha from './carrega-planilha.vue';

export default {
  name: "importacao",
  components: { carregaplanilha },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      planilha: null,
      ucs: [],
      ucsObj: {},
      ucsByName: {},
      ucsNome: {},
      ucsCodigo: {},
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    this.planilha = JSON.parse(localStorage.getItem('planilha'));
    console.log("this.planilha",this.planilha);
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
    this.loadUcs();
  },
  methods:{

    loadUcs() {
      var self = this;
      rdb.ref('/curriculo/ucs').on('value', function(snapshot) {
        self.ucs = snapshotValToArray(snapshot.val());
        self.ucsObj = snapshot.val();
        for(var key in self.ucsObj) {
          var nome = self.ucsObj[key].nome.trim().toUpperCase();
          var vet = nome.split("(");
          if(vet.length>1) {
            nome = vet[0].trim();
          }
          nome = nome.trim().toUpperCase();
          if(nome.charAt(nome.length-1) == ".") { nome = nome.substr(0,nome.length-1); };
          if(nome.charAt(nome.length-1) == ";") { nome = nome.substr(0,nome.length-1); };
          if(nome.charAt(nome.length-1) == ",") { nome = nome.substr(0,nome.length-1); };
          nome = nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
          self.ucsByName[nome] = self.ucsObj[key];
        }
        console.log("self.ucsByName", self.ucsByName);
        console.log("self.ucs",self.ucs);
      });
    },

    build() {
      var self = this;
    },

    getUCByName(nome) {
      var bestValue = 0;
      var bestNome = "";
      for(var key in this.ucsByName) {
        var value = stringSimilarity.compareTwoStrings(nome,key);
        if(value > bestValue) {
          bestValue = value;
          bestNome = key;
        }
      }
      return this.ucsByName[bestNome];
    },

    atualizaPlanilha(planilha) {
      var self = this;
      console.log("atualizaPlanilha");
      this.planilha = JSON.parse(localStorage.getItem('planilha'));
      this.planilha = planilha
      console.log("this.planilha",this.planilha);
    },

    importar() {
      console.log("importar");
      var self = this;
      var ucs = {};

      for(var idx in this.planilha) {
        var linha = this.planilha[idx];
        //var nome = linha['UNIDADE CURRICULAR'].trim().toUpperCase();
        var nome = linha['UC'].trim().toUpperCase();
        if(nome.charAt(nome.length-1) == ".") { nome = nome.substr(0,nome.length-1); };
        if(nome.charAt(nome.length-1) == ";") { nome = nome.substr(0,nome.length-1); };
        if(nome.charAt(nome.length-1) == ",") { nome = nome.substr(0,nome.length-1); };
        nome = nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        var uc = this.getUCByName(nome);
        if(ucs[uc.id] == undefined) {
          ucs[uc.id] = uc;
          ucs[uc.id].oferta2022S1 = false;
          ucs[uc.id].oferta2022S2 = false;
        } else {
          //console.log(ucs[uc.id]);
        }
        var periodo = linha["Período letivo"].trim();
        if(periodo == "2022/1") {
          ucs[uc.id].oferta2022S1 = true;
        }
        if(periodo == "2022/2") {
          ucs[uc.id].oferta2022S2 = true;
        }
        //if(linha['UNIDADE CURRICULAR'].trim().lastIndexOf("(2022/1)") > 0) {
        //  ucs[uc.id].oferta2022S1 = true;
        //}
        //if(linha['UNIDADE CURRICULAR'].trim().lastIndexOf("(2022/2)") > 0) {
        //  ucs[uc.id].oferta2022S2 = true;
        //}
      }
      console.log("ucs ["+Object.keys(ucs).length+"]",ucs);

      rdb.ref("/curriculo/ofertas/ucsLive/").set(ucs);
      console.log("Agora já Elvis!");
    }
  }
}
</script>

<style scoped>
</style>
